import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { APIService } from '../services/api.service';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserService extends BaseService<User> {
  constructor(public apiService: APIService) {
    super(apiService, 'user', 'users', '/users');
  }

  leastUserAccount(id: number, kind = 'customer'): Observable<any> {
    return this.apiService.post(`${this.resourceURL}/${id}/least_user_account?kind=${kind}`);
  }
}
