import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Campaign } from '@app/core/models/campaign.model';
import { Post, POST_THEME } from '@app/core/models/post.model';
import { IQuery } from '@app/core/models/query.model';
import { CopierService } from '@app/core/services/copier.service';
import { PublicService } from '@app/core/services/public.service';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-featured-post',
  templateUrl: './featured-post.component.html',
  styleUrls: ['./featured-post.component.css'],
})
export class FeaturedPostComponent implements OnInit {
  @Input()
  campaign: Campaign = {};

  readonly POST_THEME = POST_THEME;
  // query: IQuery = {};
  posts: Post[] = [];
  loading: boolean;
  campaignUrl: string;
  firstPost: Post;
  sort: string;

  masonryOptions: NgxMasonryOptions = {
    itemSelector: '.card',
    columnWidth: '.grid-item',
    percentPosition: true,
    gutter: 10,
    horizontalOrder: false,
    transitionDuration: '0.4s',
  };

  @ViewChild(NgxMasonryComponent, { static: false })
  masonry: NgxMasonryComponent;

  constructor(private publicService: PublicService, private route: ActivatedRoute, private copierService: CopierService) {}

  ngOnInit() {
    this.campaignUrl = `${window.location.origin}/campaigns/${this.campaign.id}`;

    if (this.campaign.url_slug) {
      this.campaignUrl = `${this.campaignUrl}/${this.campaign.url_slug}`;
    }

    if (this.campaign.allow_sort) {
      this.sort = '-publish_at';
    }

    this.loadSharePost();
  }

  loadSharePost() {
    const hashid = this.route.snapshot.queryParamMap.get('post');

    if (hashid) {
      this.publicService
        .bersamaPost({
          match: new Map([['hashid', hashid]]),
          params: new Map<string, any>([['uuid', window.localStorage.getItem('uuid')]]),
        })
        .subscribe((data) => {
          setTimeout(() => {
            this.firstPost = data.posts.length ? data.posts[0] : {};
            if (this.firstPost.id) {
              this.posts = [this.firstPost];
            }
          }, 500);

          this.initialLoad();
        });
    } else {
      this.initialLoad();
    }
  }

  loadPost(query) {
    this.loading = true;
    this.publicService.bersamaPost(query).subscribe(
      (data) => {
        setTimeout(() => {
          this.posts = [...this.posts, ...data.posts];

          // this.totalPages = data.meta.total_pages;
          this.loading = false;
        }, 500);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  initialLoad() {
    const query: IQuery = {
      match: new Map<string, any>([
        ['campaign', this.campaign.id],
        ['-id', this.firstPost ? this.firstPost.id : ''],
        ['is_feature', true],
      ]),
      params: new Map<string, any>([['uuid', window.localStorage.getItem('uuid')]]),
      sort: this.sort,
    };

    this.loadPost(query);
  }

  like(post: Post) {
    this.publicService.likeBersamaPost(post.id).subscribe((data) => {
      post.like_count = data.like_count;
      post.is_liked = data.is_liked;
    });
  }

  copyShareLink(url: string, tooltip) {
    this.copierService.copyText(url);
    tooltip.open();
  }

  imageLoaded() {
    this.masonry.layout();
  }
}
