import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input() logoImg: string;
  @Input() navItemList: any[];
  @Input() className: string;
  @Input() styledLogo = true;

  constructor() {}

  ngOnInit(): void {}

  jumpTo(href) {
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $(href).offset().top,
        },
        1500
      );
  }
}
