import { NgModule, ErrorHandler, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { APIService } from './services/api.service';
import { JWTService } from './services/jwt.service';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { HttpRequestInterceptor } from './interceptors/http.request.interceptor';
import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { UserService } from './services/user.service';
import { FeatureAccessGuard } from './guards/feature-access.guard';
import { PrintService } from './services/print.service';
import { FeatureService } from './services/feature.service';
import { FeatureAccessService } from './services/feature-access.service';
import { TenantProfileService } from './services/tenant-profile.service';
import { PublicService } from './services/public.service';
import { CopierService } from './services/copier.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    JWTService,
    APIService,
    AuthService,
    UserService,
    PrintService,
    FeatureService,
    FeatureAccessService,
    TenantProfileService,
    CanDeactivateGuard,
    AuthGuard,
    FeatureAccessGuard,
    PublicService,
    CopierService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ], // these should be singleton
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    super(parentModule);
  }
}
