import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseSurveyComponent } from '../base-survey/base-survey.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-survey-radio-text',
  templateUrl: './survey-radio-text.component.html',
  styleUrls: ['./survey-radio-text.component.css'],
  providers: [{ provide: BaseSurveyComponent, useExisting: SurveyRadioTextComponent }],
})
export class SurveyRadioTextComponent extends BaseSurveyComponent {
  form: FormGroup;

  result: [] = [];

  constructor(public formBuilder: FormBuilder) {
    super();
    this.form = this.formBuilder.group({
      radio: [{ value: '' }, Validators.required],
    });
  }

  ngOnInit() {
    this.sortedOption = _.orderBy(this.choice, ['ordering']);
    this.form.get('radio').valueChanges.subscribe((value) => {
      this.result = _.filter(this.choice, { id: +value });
    });
  }

  getChoice() {
    return this.result;
  }
}
