import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { APIService } from '../services/api.service';
import { JWTService } from '../services/jwt.service';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { SkipTokenInterceptor } from '../interceptors/token.interceptor';
import { User } from '../models/user.model';
import { UserService } from './user.service';

@Injectable()
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser$ = this.currentUserSubject.asObservable().pipe(distinctUntilChanged(AuthService.UserComparer));

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  private cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private apiService: APIService, private userService: UserService, private jwtService: JWTService) {}

  static UserComparer(x: User, y: User): boolean {
    // temporary we ignore the updated_at change.
    x.modify_at = y.modify_at;
    return JSON.stringify(x) === JSON.stringify(y);
  }

  // Re-authenticate not implement yet
  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }
  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  login(email: string, password: string, rememberMe: boolean): Observable<any> {
    this.jwtService.saveRememberMe(rememberMe);

    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

    return this.apiService.post('/rest-auth/login/', { email, password }, { headers }).pipe(
      mergeMap((data) => {
        this.jwtService.saveToken(data.token);
        return this.userService
          .one(data.user.pk, {
            // include: ['customer', 'outlet']
          })
          .pipe(map((user) => [data.token, user[this.userService.single]]));
      }),
      map((data) => {
        this.setAuth(data[0], data[1]);
        return data;
      })
    );
  }

  // Verify JWT in localstorage with server & load user's info.
  verify(): void {
    // If JWT detected, attempt to get & store user's info
    const token = this.jwtService.getToken();
    if (token) {
      this.apiService
        .get('/rest-auth/user/', { token })
        .pipe(
          mergeMap((data) =>
            this.userService
              .one(data.pk, {
                // include: ['customer', 'outlet']
              })
              .pipe(map((user) => [token, user[this.userService.single]]))
          )
        )
        .subscribe(
          (data) => this.setAuth(data[0], data[1]),
          (err) => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  // Refresh JWT in localstorage with server & load user's info.
  refresh(): void {
    // If JWT detected, attempt to get & store user's info
    const token = this.jwtService.getToken();
    if (token) {
      this.apiService
        .get('/rest-auth/user/', { token })
        .pipe(
          mergeMap((data) =>
            this.userService
              .one(data.pk, {
                // include: ['customer', 'outlet']
              })
              .pipe(map((user) => [token, user[this.userService.single]]))
          )
        )
        .subscribe(
          (data) => this.setAuth(data[0], data[1]),
          (err) => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(token: string, user: User): void {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(token);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth(): void {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  updateProfile(user): Observable<User> {
    return this.apiService.put(`/users/${user.id}`, user).pipe(
      map((data) => {
        // Update the currentUser observable
        this.currentUserSubject.next(data.user);
        return data.user;
      })
    );
  }

  changePassword(form): Observable<any> {
    return this.apiService.post('/rest-auth/password/change/', form);
  }

  resetPassword(form): Observable<any> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

    return this.apiService.post('/rest-auth/password/reset/', form, { headers });
  }

  confirmResetPassword(form): Observable<any> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

    return this.apiService.post('/rest-auth/password/reset/confirm/', form, { headers });
  }

  currentUser(): User {
    return this.currentUserSubject.value;
  }
}
