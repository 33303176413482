import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const SkipHttpRequestInterceptor = 'SkipHttpRequestInterceptor';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(SkipHttpRequestInterceptor)) {
      request = request.clone({ headers: request.headers.delete(SkipHttpRequestInterceptor) });
    } else {
      const headersConfig = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };

      request = request.clone({ setHeaders: headersConfig });
    }

    return next.handle(request);
  }
}
