import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SofboxModule } from '../sofbox/sofbox.module';
import { BannerComponent } from './components/banner/banner.component';
import { AboutComponent } from './components/about/about.component';

// Import your library
import { NgxMasonryModule } from 'ngx-masonry';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BersamaComponent } from './components/bersama/bersama.component';

@NgModule({
  declarations: [HomeComponent, BannerComponent, AboutComponent, BersamaComponent],
  imports: [CommonModule, SofboxModule, NgxMasonryModule, InfiniteScrollModule, HomeRoutingModule],
})
export class HomeModule {}
