import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { Campaign } from '@app/core/models/campaign.model';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from '@app/core/services/public.service';
import { takeUntil } from 'rxjs/operators';
import { CampaignPostsComponent } from './components/campaign-posts/campaign-posts.component';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignComponent implements OnInit, OnDestroy {
  id: number;
  campaign: Campaign = {};

  private unsubscribeAll: Subject<any>;

  @ViewChild(CampaignPostsComponent, { static: false })
  postsComponent: CampaignPostsComponent;

  constructor(private router: Router, private route: ActivatedRoute, private publicService: PublicService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribeAll)).subscribe((params) => {
      this.id = +params.id;
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  refresh(): void {
    this.campaign = {};
    this.publicService.campaign(this.id).subscribe((data) => {
      setTimeout(() => {
        this.campaign = data.campaign || {};
      });
    });
  }

  onPostAdded(): void {
    if (this.postsComponent) {
      this.postsComponent.refresh();
    }
  }
}
