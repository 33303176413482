import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit, ViewChild, ElementRef, DoCheck, ViewChildren } from '@angular/core';
import { CAMPAIGN_CATEGORY, Campaign } from '@app/core/models/campaign.model';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from '@app/core/services/public.service';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingComponent implements OnInit, OnDestroy, AfterViewInit{
  readonly categories = Object.values(CAMPAIGN_CATEGORY);

  private unsubscribeAll: Subject<any>;

  fragment : string;

  constructor(private router: Router, private route: ActivatedRoute, private publicService: PublicService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
     this.route.fragment.subscribe(fragment => { 
       this.fragment = fragment;
      }
    ); 
  }

  
  ngAfterViewInit(): void {
    try {
        setTimeout(() => {
          if(this.fragment){
          document.querySelector('#' + this.fragment).scrollIntoView();
        }
        }, 1000);  
    } catch (e) { 
     }
  }


  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
