import { Component, OnInit, ViewChild, ElementRef, Inject, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Post, POST_KIND, POST_THEME } from '@app/core/models/post.model';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { PublicService } from '@app/core/services/public.service';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { Campaign } from '@app/core/models/campaign.model';
import { ActivatedRoute } from '@angular/router';
import { IQuery } from '@app/core/models/query.model';
import { CopierService } from '@app/core/services/copier.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-campaign-posts',
  templateUrl: './campaign-posts.component.html',
  styleUrls: ['./campaign-posts.component.css'],
})
export class CampaignPostsComponent implements OnInit {
  @Input()
  campaign: Campaign = {};

  readonly POST_THEME = POST_THEME;
  readonly INITIAL_PAGE = 2;

  currentPage = 1;
  totalPages = 0;
  totalPosts: number;

  posts: Post[] = [];
  firstPost: Post;
  loading = false;
  campaignUrl: string;
  keyword: string;
  sort: string;

  masonryOptions: NgxMasonryOptions = {
    itemSelector: '.card',
    columnWidth: '.grid-item',
    percentPosition: true,
    gutter: 10,
    horizontalOrder: false,
    transitionDuration: '0.4s',
  };

  @ViewChild(NgxMasonryComponent, { static: false })
  masonry: NgxMasonryComponent;

  constructor(private route: ActivatedRoute, private publicService: PublicService, private copierService: CopierService) {}

  ngOnInit(): void {
    this.campaignUrl = `${window.location.origin}/campaigns/${this.campaign.id}`;

    if (this.campaign.url_slug) {
      this.campaignUrl = `${this.campaignUrl}/${this.campaign.url_slug}`;
    }

    if (this.campaign.allow_sort) {
      this.sort = '-publish_at';
    }

    this.loadSharePost();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  initialLoad() {
    const query: IQuery = {
      meta: {
        page: this.currentPage,
        per_page: this.INITIAL_PAGE * environment.pageSize,
      },
      match: new Map([
        ['campaign', this.campaign.id],
        ['-id', this.firstPost ? this.firstPost.id : ''],
      ]),
      params: new Map<string, any>([
        ['keyword', this.keyword],
        ['uuid', window.localStorage.getItem('uuid')],
      ]),
      sort: this.sort,
    };

    this.loadPost(query);
  }

  onScroll() {
    if (this.currentPage < this.totalPages) {
      this.more();
    }
  }

  more() {
    this.currentPage++;

    const query = {
      meta: {
        page: this.currentPage,
        per_page: environment.pageSize,
      },
      match: new Map([
        ['campaign', this.campaign.id],
        ['-id', this.firstPost ? this.firstPost.id : ''],
      ]),
      params: new Map<string, any>([
        ['keyword', this.keyword],
        ['uuid', window.localStorage.getItem('uuid')],
      ]),
      sort: this.sort,
    };

    this.loadPost(query);
  }

  onSearch() {
    this.currentPage = 1;
    this.posts = [];
    this.firstPost = {};
    this.masonry.reloadItems();
    this.initialLoad();
  }

  onSort() {
    this.currentPage = 1;
    this.posts = [];
    this.firstPost = {};
    this.masonry.reloadItems();
    this.initialLoad();
  }

  refresh() {
    this.currentPage = 1;
    this.posts = [];
    this.firstPost = {};
    this.masonry.reloadItems();
    this.initialLoad();
  }

  loadPost(query) {
    this.loading = true;
    this.publicService.bersamaPost(query).subscribe(
      (data) => {
        setTimeout(() => {
          this.posts = [...this.posts, ...data.posts];
          this.totalPosts = data.meta.total_results;
          if (this.currentPage === 1) {
            this.currentPage = this.INITIAL_PAGE;
            this.totalPages = _.ceil(data.meta.total_results / environment.pageSize);
          } else {
            this.totalPages = data.meta.total_pages;
          }
          this.loading = false;
        }, 500);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  like(post: Post) {
    this.publicService.likeBersamaPost(post.id).subscribe((data) => {
      post.like_count = data.like_count;
      post.is_liked = data.is_liked;
    });
  }

  copyShareLink(url: string, tooltip) {
    this.copierService.copyText(url);
    tooltip.open();
  }

  imageLoaded() {
    this.masonry.layout();
  }

  loadSharePost() {
    const hashid = this.route.snapshot.queryParamMap.get('post');

    if (hashid) {
      this.publicService
        .bersamaPost({
          match: new Map([['hashid', hashid]]),
          params: new Map<string, any>([['uuid', window.localStorage.getItem('uuid')]]),
        })
        .subscribe((data) => {
          setTimeout(() => {
            this.firstPost = data.posts.length ? data.posts[0] : {};
            if (this.firstPost.id) {
              this.posts = [this.firstPost];
            }
          }, 500);
          this.initialLoad();
        });
    } else {
      this.initialLoad();
    }
  }
}
