import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SofboxModule } from '@app/sofbox/sofbox.module';
//import { OwlModule } from 'ngx-owl-carousel';

import '../../assets/js/custom';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [LayoutComponent, HeaderComponent, FooterComponent, NavbarComponent],
  imports: [
    CommonModule,
    NgbCollapseModule,
    NgbDropdownModule,
    //OwlModule,
    SofboxModule,
    RouterModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
