import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutComponent implements OnInit, AfterViewInit {
  closeResult: string;
  loading = false;

  @ViewChild('iframe', { static: false })
  iframe: ElementRef;

  constructor(private renderer: Renderer2, private modalService: NgbModal) {}

  ngOnInit() {}

  open(content) {
    this.loading = true;

    this.modalService.open(content, { size: 'lg', windowClass: 'custom-modal-lg' });
  }

  ngAfterViewInit() {}

  onIframeLoad() {
    this.loading = false;
  }
}
