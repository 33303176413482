import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseSurveyComponent } from '../base-survey/base-survey.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-survey-checkbox-image',
  templateUrl: './survey-checkbox-image.component.html',
  styleUrls: ['./survey-checkbox-image.component.css'],
  providers: [
    {
      provide: BaseSurveyComponent,
      useExisting: SurveyCheckboxImageComponent,
    },
  ],
})
export class SurveyCheckboxImageComponent extends BaseSurveyComponent {
  form: FormGroup;

  result: any[] = [];

  clicked = false;

  checkboximage: FormArray;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.form = this.formBuilder.group({
      checkboximage: this.formBuilder.array([], [Validators.required]),
    });
  }

  ngOnInit() {
    this.sortedOption = _.orderBy(this.choice, 'ordering');
  }

  onCheckboxChange(e) {
    this.checkboximage = this.form.get('checkboximage') as FormArray;

    if (e.target.checked) {
      this.checkboximage.push(new FormControl(e.target.value));
    } else {
      const index = this.checkboximage.controls.findIndex((x) => x.value === e.target.value);
      this.checkboximage.removeAt(index);
    }

    this.result = _(this.choice).keyBy('id').at(this.checkboximage.value).value();
  }

  getChoice() {
    this.checkboximage.clear();
    return this.result;
  }
}
