import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SofboxModule } from '@app/sofbox/sofbox.module';

import { CampaignRoutingModule } from './campaign-routing.module';
import { CampaignComponent } from './campaign.component';
import { CampaignBannerComponent } from './components/campaign-banner/campaign-banner.component';
import { CampaignAboutComponent } from './components/campaign-about/campaign-about.component';
import { CampaignPostsComponent } from './components/campaign-posts/campaign-posts.component';
import { CampaignMilestoneComponent } from './components/campaign-milestone/campaign-milestone.component';

// Import your library
import { NgxMasonryModule } from 'ngx-masonry';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CampaignPostFormComponent } from './components/campaign-post-form/campaign-post-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareModule } from '@ngx-share/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeaturedPostComponent } from './components/featured-post/featured-post.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { SurveyListComponent } from './components/survey-list/survey-list.component';
import { SurveyDropdownComponent } from './components/survey-dropdown/survey-dropdown.component';
import { BaseSurveyComponent } from './components/base-survey/base-survey.component';
import { SurveyCheckboxImageComponent } from './components/survey-checkbox-image/survey-checkbox-image.component';
import { SurveyCheckboxTextComponent } from './components/survey-checkbox-text/survey-checkbox-text.component';
import { SurveyRadioTextComponent } from './components/survey-radio-text/survey-radio-text.component';
import { SurveyResultComponent } from './components/survey-result/survey-result.component';
import { SurveyScaleComponent } from './components/survey-scale/survey-scale.component';

@NgModule({
  declarations: [
    CampaignComponent,
    CampaignBannerComponent,
    CampaignAboutComponent,
    CampaignPostsComponent,
    CampaignPostFormComponent,
    CampaignMilestoneComponent,
    FeaturedPostComponent,
    BaseSurveyComponent,
    SurveyListComponent,
    SurveyDropdownComponent,
    SurveyCheckboxImageComponent,
    SurveyCheckboxTextComponent,
    SurveyRadioTextComponent,
    SurveyScaleComponent,
    SurveyResultComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SofboxModule,
    NgxMasonryModule,
    InfiniteScrollModule,
    NgbModule,
    ShareModule,
    CampaignRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
})
export class CampaignModule {}
