import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Campaign } from '@app/core/models/campaign.model';
import { cpuUsage } from 'process';
import moment from 'moment';
@Component({
  selector: 'app-campaign-milestone',
  templateUrl: './campaign-milestone.component.html',
  styleUrls: ['./campaign-milestone.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignMilestoneComponent implements OnInit {
  @Input()
  campaign: Campaign = {};

  milestoneState = 0;

  constructor() {}

  ngOnInit() {
    this.compareDate();
  }

  compareDate(): void {
    this.milestoneState = 0;
    const today = new Date();

    const milestone = [new Date(this.campaign.milestone_1_date), new Date(this.campaign.milestone_2_date), new Date(this.campaign.milestone_3_date)];

    for (let i = 0; i < milestone.length; i++) {
      if (today >= milestone[i]) {
        this.milestoneState = i + 1;
      }
    }
  }
}
