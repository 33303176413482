import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseSurveyComponent } from '../base-survey/base-survey.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-survey-dropdown',
  templateUrl: './survey-dropdown.component.html',
  styleUrls: ['./survey-dropdown.component.css'],
  providers: [{ provide: BaseSurveyComponent, useExisting: SurveyDropdownComponent }],
})
export class SurveyDropdownComponent extends BaseSurveyComponent {
  form: FormGroup;

  result: any[] = [];

  selectedChoice = null;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.form = this.formBuilder.group({
      dropdown: [{ value: '' }, Validators.required],
    });
  }

  ngOnInit() {
    this.sortedOption = _.orderBy(this.choice, ['ordering']);
    this.form.get('dropdown').valueChanges.subscribe((value) => {
      this.result = _.filter(this.choice, { id: +value });
    });
  }

  getChoice() {
    this.selectedChoice = null;
    this.form.markAsUntouched();
    return this.result;
  }
}
