export interface Post {
  id?: number;
  hashid?: string;
  campaign?: number;
  poster_name?: string;
  poster_email?: string;
  description?: string;
  poster_instagram?: string;
  kind?: string;
  text?: string;
  short_text?: string;
  url?: string;
  headline?: string;
  image?: string;
  theme?: string;
  publish_at?: string;
  ordering?: number;
  category?: string;
  tag?: string;
  like_count?: number;
  is_liked?: boolean;
  edit_by?: number;
  is_feature?: boolean;
}

export enum POST_KIND {
  KIND_NEWS = 'news',
  KIND_IMAGE = 'image',
  KIND_MESSAGE = 'message',
}

export enum POST_THEME {
  THEME_1 = 'theme-1',
  THEME_2 = 'theme-2',
  THEME_3 = 'theme-3',
  THEME_4 = 'theme-4',
  THEME_5 = 'theme-5',
  THEME_6 = 'theme-6',
  THEME_WHITE = 'theme-white',
}

export const POST_THEME_OPTIONS = new Map<string, any>([
  [
    POST_THEME.THEME_1,
    {
      title: 'Pattens Blue',
      background: '#cdf5ff',
    },
  ],
  [
    POST_THEME.THEME_2,
    {
      title: 'Vista Green',
      background: '#9cdcbe',
    },
  ],
  [
    POST_THEME.THEME_3,
    {
      title: 'Grandis Orange',
      background: '#ffd067',
    },
  ],
  [
    POST_THEME.THEME_4,
    {
      title: 'Your Pink',
      background: '#ffc1c1',
    },
  ],
  [
    POST_THEME.THEME_5,
    {
      title: 'Gainsboro Grey',
      background: '#d9d9d9',
    },
  ],
  [
    POST_THEME.THEME_6,
    {
      title: 'Jagged Ice Green',
      background: '#c5e7e4',
    },
  ],
  [
    POST_THEME.THEME_WHITE,
    {
      title: 'White',
      background: '#ffffff',
    },
  ],
]);
