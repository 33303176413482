import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { APIService } from '@app/core/services/api.service';
import { FeatureAccess } from '../models/feature-access.model';

@Injectable()
export class FeatureAccessService extends BaseService<FeatureAccess> {
  constructor(public apiService: APIService) {
    super(apiService, 'feature-access', 'feature-accesses', '/feature-accesses');
  }
}
