import { Component, OnInit, ViewChild, ElementRef, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Post, POST_KIND, POST_THEME } from '@app/core/models/post.model';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { PublicService } from '@app/core/services/public.service';
import { environment } from '@env/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-bersama',
  templateUrl: './bersama.component.html',
  styleUrls: ['./bersama.component.css'],
})
export class BersamaComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly POST_THEME = POST_THEME;
  readonly INITIAL_PAGE = 2;

  currentPage = 1;
  totalPages = 0;

  posts: Post[] = [];
  loading = false;

  masonryOptions: NgxMasonryOptions = {
    itemSelector: '.card',
    columnWidth: '.grid-item',
    percentPosition: true,
    gutter: 10,
    horizontalOrder: false,
    transitionDuration: '0.4s',
  };

  @ViewChild(NgxMasonryComponent, { static: false })
  masonry: NgxMasonryComponent;

  constructor(private publicService: PublicService) {}

  ngOnInit(): void {
    const query = {
      meta: {
        page: this.currentPage,
        per_page: this.INITIAL_PAGE * environment.pageSize,
      },
    };

    this.loadPost(query);
    this.currentPage = this.INITIAL_PAGE;
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  onScroll() {
    if (this.currentPage < this.totalPages) {
      this.more();
    }
  }

  more() {
    this.currentPage++;

    const query = {
      meta: {
        page: this.currentPage,
        per_page: environment.pageSize,
      },
    };

    this.loadPost(query);
  }

  loadPost(query) {
    this.loading = true;
    this.publicService.bersamaPost(query).subscribe(
      (data) => {
        setTimeout(() => {
          this.posts = [...this.posts, ...data.posts];

          this.totalPages = data.meta.total_pages;
          this.loading = false;
        }, 500);
      },
      (error) => {}
    );
  }
}
