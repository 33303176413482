import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, AfterViewInit {
  @Input() logoImg: string;
  @Input() navItemList: any[];
  @Input() className: string;
  @Input() styledLogo = true;

  public isMenuCollapsed = true;

  constructor(private router: Router) {}

  @HostListener('document: click')
  clickedOutside(): void {
    this.isMenuCollapsed = true;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  toggleMenu(event: any): void {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    event.preventDefault();
    event.stopPropagation();
  }

  toggleDropdown(event: any): any {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}
