import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SofboxModule } from '@app/sofbox/sofbox.module';

import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about.component';
import { AboutBannerComponent } from './components/about-banner/about-banner.component';
import { AboutAboutComponent } from './components/about-about/about-about.component';

@NgModule({
  declarations: [AboutComponent, AboutBannerComponent, AboutAboutComponent],
  imports: [CommonModule, SofboxModule, AboutRoutingModule],
})
export class AboutModule {}
