import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Answer } from '@app/core/models/survey-answer';
import { Question, SurveyQuestion } from '@app/core/models/survey-question.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-base-survey',
  templateUrl: './base-survey.component.html',
  styleUrls: ['./base-survey.component.css'],
})
export class BaseSurveyComponent implements OnInit, OnChanges {
  // form: FormGroup;
  // question: any;
  @Input()
  question: SurveyQuestion = {};

  @Input()
  choice: any[] = [];

  @Output()
  choiceChange = new EventEmitter<Question[]>();

  @Input()
  answer: Answer[];

  sortedOption: any[] = [];
  showMainContent = false;

  constructor() {}

  ngOnInit() {}

  getChoice(): any[] {
    return [];
  }

  ngOnChanges() {
    this.sortedOption = _.orderBy(this.choice, ['ordering']);
  }

  changeToResultMode() {
    this.showMainContent = true;
  }

  changeToSurveyMode() {
    this.showMainContent = false;
  }
}
