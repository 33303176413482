import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SkipTokenInterceptor } from '../interceptors/token.interceptor';
import { IQuery } from '../models/query.model';
import { APIService } from './api.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyQuestionService extends BaseService<any> {
  constructor(public apiService: APIService) {
    super(apiService, '', 'survey-questions', '/public/survey-choices');
  }

  getSurvey(query: IQuery = {}): Observable<any> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');
    const params = this.getHttpParams(query);

    return this.apiService.get(`${this.resourceURL}`, { params, headers });
  }
}
