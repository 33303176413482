import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-black-card-style1',
  templateUrl: './black-card-style1.component.html',
  styleUrls: ['./black-card-style1.component.css']
})
export class BlackCardStyle1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
