export interface Campaign {
  id?: number;
  hashid?: string;
  name?: string;
  short_description?: string;
  long_description?: string;
  update_description?: string;
  image?: string;
  banner?: string;
  post_name_enable?: boolean;
  post_phone_enable?: boolean;
  post_email_enable?: boolean;
  post_image_enable?: boolean;
  post_description_enable?: boolean;
  post_url_enable?: boolean;
  post_instagram_enable?: boolean;
  post_name_title?: string;
  post_phone_title?: string;
  post_email_title?: string;
  post_image_title?: string;
  post_description_title?: string;
  post_url_title?: string;
  post_instagram_title?: string;
  post_description_min_length?: number;
  post_description_max_length?: number;

  allow_search?: boolean;
  allow_post?: boolean;
  allow_sort?: boolean;
  allow_display_submission_counter?: boolean;

  start_at?: string;
  end_at?: string;
  external_embed_code?: string;
  url_slug?: string;
  edit_by?: number;
  is_active?: boolean;
  create_at?: string;
  modify_at?: string;

  category?: string;
  status?: string;
  is_feature?: boolean;

  is_hero_campaign?: boolean;
  hero_dynamic_statement?: string;
  hero_choice_title?: string;
  hero_name_title?: string;
  hero_phone_title?: string;
  hero_email_title?: string;
  hero_industry_title?: string;

  // milestone
  milestone_title?: string;
  milestone_1_name?: string;
  milestone_1_date?: string;
  milestone_2_name?: string;
  milestone_2_date?: string;
  milestone_3_name?: string;
  milestone_3_date?: string;
  has_milestone?: boolean;

  call_to_action_title?: string;
  call_to_action_image?: string;
  is_survey_response?: boolean;
}

export enum CAMPAIGN_CATEGORY {
  BETTER_LIVING = 'better-living',
  BETTER_COMMUNITY = 'better-community',
}

export enum CAMPAIGN_STATUS {
  ACTIVE = 'active',
  PAST = 'past',
}

export const HERO_CAMPAIGN_NOMINATION_CHOICE = new Map<string, string>([
  ['self', 'Yourself'],
  ['other', 'Someone else/Another hero'],
]);

export const HERO_CAMPAIGN_INDUSTRY_CHOICE = new Map<string, string>([
  ['Healthcare/Medical worker', 'Healthcare/Medical worker'],
  ['Educator', 'Educator'],
  ['Armed forces personnel', 'Armed forces personnel'],
  ['NGO personnel', 'NGO personnel'],
  ['Arts/Broadcasting/Publishing/Music/Creative', 'Arts/Broadcasting/Publishing/Music/Creative'],
  ['Hospitality', 'Hospitality'],
  ['F&B', 'F&B'],
  ['Delivery riders/Drivers', 'Delivery riders/Drivers'],
  ['Caregivers/Homemakers', 'Caregivers/Homemakers'],
  ['Builders', 'Builders'],
  ['Public/Government Services', 'Public/Government Services'],
  ['Others', 'Others'],
]);
