import { Component, Input, OnInit } from '@angular/core';
import { Answer } from '@app/core/models/survey-answer';
import { Question, SurveyQuestion } from '@app/core/models/survey-question.model';

@Component({
  selector: 'app-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.css'],
})
export class SurveyResultComponent implements OnInit {
  constructor() {}

  @Input()
  answer: Answer[];

  ngOnInit() {}
}
