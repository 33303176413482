import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-black-card-style7',
  templateUrl: './black-card-style7.component.html',
  styleUrls: ['./black-card-style7.component.css']
})
export class BlackCardStyle7Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
