import { Component, OnInit, OnDestroy } from '@angular/core';
import { PluginsService } from './sofbox/plugins.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { JWTService } from './core/services/jwt.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { v4 as uuidv4 } from 'uuid';
import { HomeComponent } from './home/home.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  location = window.location;

  private unsubscribeAll: Subject<any>;

  constructor(private plugins: PluginsService, private router: Router, private authService: AuthService, private jwtService: JWTService) {
    this.unsubscribeAll = new Subject();
  }

  title = 'dtoideas-front';

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd && evt instanceof HomeComponent)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.jwtService.ready$.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      if (environment.sameOrigin) {
        environment.apiUrl = `//${this.location.host}/${environment.apiUrl}`;
      }

      let uuid = localStorage.getItem('uuid');
      if (!uuid || uuid.length !== 36) {
        uuid = uuidv4();
        localStorage.setItem('uuid', uuid);
      }

      this.authService.verify();
    });

    console.log('version', environment.version);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
