import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { CAMPAIGN_CATEGORY, Campaign } from '@app/core/models/campaign.model';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from '@app/core/services/public.service';
import { SplitPipe } from '@app/pipes/split.pipe';

import * as _ from 'lodash';

@Component({
  selector: 'app-landing-active-campaigns',
  templateUrl: './landing-active-campaigns.component.html',
  styleUrls: ['./landing-active-campaigns.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingActiveCampaignsComponent implements OnInit, OnDestroy {
  readonly CAMPAIGN_CATEGORY = CAMPAIGN_CATEGORY;
  @Input() category: string;
  campaigns: Campaign[] = [];

  private unsubscribeAll: Subject<any>;

  constructor(private router: Router, private route: ActivatedRoute, private publicService: PublicService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  refresh(): void {
    const query = {
      match: new Map<string, any>([
        ['status', 'active'],
        ['category', this.category],
      ]),
    };
    this.publicService.campaigns(query).subscribe((data) => {
      this.campaigns = data.campaigns || [];
    });
  }

  onCampaignClick(campaign: Campaign): void {
    if (campaign.url_slug) {
      this.router.navigate(['/campaigns', campaign.id, campaign.url_slug]);
    } else {
      this.router.navigate(['/campaigns', campaign.id]);
    }
  }
}
