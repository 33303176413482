import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { JWTService } from './jwt.service';
import { environment } from '@env/environment';
import { SkipHttpRequestInterceptor } from '../interceptors/http.request.interceptor';

import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable()
export class APIService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading = this.loadingSubject.asObservable();

  constructor(private http: HttpClient, private jwtService: JWTService) {}

  private handleError(error: HttpErrorResponse): Observable<never> {
    let message = 'Something bad happened; please try again later.';

    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        message = 'No Internet Connection';
      } else if (error.status >= 400 && error.status < 500) {
        // Handle Http Error (error.status === 403, 404...)
        if (error.status === 401 || error.status === 403) {
          message = error.statusText;
          if (error.error.message) {
            message = error.error.message;
          }
        } else if (error.error.non_field_errors) {
          message = error.error.non_field_errors[0];
        } else if (error.error.message) {
          message = error.error.message;
        } else if (error.error.detail) {
          message = error.error.detail;
        } else {
          for (const field in error.error) {
            if (error.error.hasOwnProperty(field)) {
              message = `${field}: ${error.error[field]}`;
            }
          }
        }
      } else {
        message = error.message;
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
    }

    // return an observable with a user-facing error message
    return throwError(message);
  }

  get(path: string, options?: any): Observable<any> {
    this.loadingSubject.next(true);
    return this.http.get(`${environment.apiUrl}${path}`, options).pipe(
      catchError(this.handleError),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }

  put(path: string, body: any = {}): Observable<any> {
    this.loadingSubject.next(true);
    return this.http.put(`${environment.apiUrl}${path}`, JSON.stringify(body)).pipe(
      catchError(this.handleError),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }

  post(path: string, body: any = {}, options?: any): Observable<any> {
    this.loadingSubject.next(true);
    return this.http.post(`${environment.apiUrl}${path}`, JSON.stringify(body), options).pipe(
      catchError(this.handleError),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }

  delete(path): Observable<any> {
    this.loadingSubject.next(true);
    return this.http.delete(`${environment.apiUrl}${path}`).pipe(
      catchError(this.handleError),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }

  postFormData(path: string, formData: FormData, headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    this.loadingSubject.next(true);
    headers = headers.set(SkipHttpRequestInterceptor, '');

    return this.http.post(`${environment.apiUrl}${path}`, formData, { headers }).pipe(
      catchError(this.handleError),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }

  download(path: string, params: HttpParams = new HttpParams(), fileName?: string): Observable<any> {
    this.loadingSubject.next(true);
    const headers = new HttpHeaders().set(SkipHttpRequestInterceptor, '');

    return this.http
      .get(`${environment.apiUrl}${path}`, {
        headers: headers,
        responseType: 'blob',
        observe: 'response',
        params: params,
      })
      .pipe(
        tap((res) => {
          const whichFileName = fileName ? fileName : this.getFileNameFromResponseContentDisposition(res);
          this.saveFile(res.body, whichFileName);
        }),
        catchError(this.handleError),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      );
  }

  private saveFile(blobContent: Blob, fileName: string) {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

  private getFileNameFromResponseContentDisposition(res) {
    const contentDisposition: string = res.headers.get('content-disposition') || '';

    let fileName = 'untitled';
    let matches = /filename[^;=\n]=(UTF-8(['"]))?(.*)/i.exec(contentDisposition);

    if (matches != null && matches[3]) {
      fileName = matches[3];
    } else {
      matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1];
      }
    }

    fileName = fileName.replace(/['"]/g, '');

    return decodeURI(fileName);
  }
}
