import { Injectable } from '@angular/core';
import { APIService } from '@app/core/services/api.service';
import { TenantProfile } from '../models/tenant-profile.model';
import { HttpHeaders } from '@angular/common/http';
import { SkipTokenInterceptor } from '../interceptors/token.interceptor';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TenantProfileService {
  constructor(public apiService: APIService) {}

  get(): Observable<TenantProfile> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

    return this.apiService.get(`/tenant-profile/get`, { headers }).pipe(map((data) => data['tenant-profile']));
  }
}
