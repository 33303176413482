import { Injectable } from '@angular/core';
import { APIService } from '@app/core/services/api.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { SkipTokenInterceptor } from '../interceptors/token.interceptor';
import { BaseService } from './base.service';
import { IQuery } from '../models/query.model';
import { Post } from '../models/post.model';
import { Answer } from '../models/survey-answer';

@Injectable()
export class PublicService extends BaseService<any> {
  constructor(public apiService: APIService) {
    super(apiService, '', '', '/public');
  }

  bersamaPost(query: IQuery = {}): Observable<any> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');
    const params = this.getHttpParams(query);

    return this.apiService.get(`${this.resourceURL}/bersama-posts`, { params, headers });
  }

  addBersamaPost(model: Post): Observable<Post> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

    return this.apiService.post(`${this.resourceURL}/bersama-posts`, model, { headers }).pipe(map((data) => data.post));
  }

  uploadBersamaPostImage(id: number, formData: FormData): Observable<Post> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');
    return this.apiService.postFormData(`${this.resourceURL}/bersama-posts/${id}/upload_image`, formData, headers).pipe(map((data) => data.post));
  }

  likeBersamaPost(id: number): Observable<Post> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

    const uuid = localStorage.getItem('uuid');

    return this.apiService.post(`${this.resourceURL}/bersama-posts/${id}/like`, { uuid }, { headers }).pipe(map((data) => data.post));
  }

  campaigns(query: IQuery = {}): Observable<any> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');
    const params = this.getHttpParams(query);

    return this.apiService.get(`${this.resourceURL}/campaigns`, { params, headers });
  }

  campaign(id: number, query: IQuery = {}): Observable<any> {
    if (!id) {
      return of({});
    }

    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');
    const params = this.getHttpParams(query);

    return this.apiService.get(`${this.resourceURL}/campaigns/${id}`, { params, headers });
  }

  getHomeBanner(): Observable<any[]> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');

    return this.apiService.get(`${this.resourceURL}/get-home-banner`, { headers });
  }

  getSurveyResult(id: number): Observable<any[]> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');
    const uuid = localStorage.getItem('uuid');
    return this.apiService.get(`${this.resourceURL}/survey-questions/${id}/result?uuid=${uuid}`, { headers });
  }

  submitAnswer(questionId: number, surveyChoicesId: number): Observable<Answer> {
    const headers = new HttpHeaders().set(SkipTokenInterceptor, '');
    const uuid = localStorage.getItem('uuid');

    const postData = {
      uuid: uuid,
      survey_choice: surveyChoicesId,
    };

    return this.apiService.post(`${this.resourceURL}/survey-questions/${questionId}/submit_answer`, postData, {
      headers,
    });
  }
}
