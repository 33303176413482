import { Component, OnInit, Input } from '@angular/core';
import { Campaign } from '@app/core/models/campaign.model';

@Component({
  selector: 'app-campaign-banner',
  templateUrl: './campaign-banner.component.html',
  styleUrls: ['./campaign-banner.component.css'],
})
export class CampaignBannerComponent implements OnInit {
  @Input()
  campaign: Campaign = {};

  constructor() {}

  ngOnInit() {}
}
