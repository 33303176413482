import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SofboxModule } from './sofbox/sofbox.module';
import { RecaptchaModule } from 'ng-recaptcha';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ContactFormService } from './sofbox/components/contact-form/contact-form.service';
import { HomeModule } from './home/home.module';
import { CoreModule } from './core/core.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMasonryModule } from 'ngx-masonry';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShareModule } from '@ngx-share/core';
import { LayoutModule } from './layout/layout.module';
import { CampaignModule } from './campaign/campaign.module';
import { LandingModule } from './landing/landing.module';
import { AboutModule } from './about/about.module';
import { Platform } from '@angular/cdk/platform';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    SofboxModule,
    RecaptchaModule,
    NgbModule,
    NgxMasonryModule,
    InfiniteScrollModule,
    ShareModule,

    LayoutModule,
    HomeModule,
    CampaignModule,
    LandingModule,
    AboutModule,

    CoreModule, // Singleton objects (services, components that are loaded only once, etc.)
    AppRoutingModule,
  ],
  providers: [ContactFormService, Platform],
  bootstrap: [AppComponent],
})
export class AppModule {}
