import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// @ts-ignore
import logoImg from '../../assets/images/dtoideas-logo.png';
import { PluginsService } from '@app/sofbox/plugins.service';
import { Campaign } from '@app/core/models/campaign.model';
import { PublicService } from '@app/core/services/public.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit {
  campaigns: Campaign[] = [];

  public navLogo: string = logoImg;
  public navItems: any = [
    { href: '', title: 'Home' },
    { href: 'about', title: 'About' },
    { href: '', title: 'Active Campaigns' },
  ];
  public socialNavItems: any = [
    { href: 'https://www.instagram.com/jomdto/', icon: '<i class="fab fa-instagram"></i>' },
    { href: 'https://www.facebook.com/jomdto/', icon: '<i class="fab fa-facebook-f"></i>' },
  ];
  public footerText = `© ${new Date().getFullYear()} Sime Darby Property Berhad. All rights reserved`;

  constructor(private plugins: PluginsService, private publicService: PublicService) {}

  ngOnInit(): void {
    // Init all plugins...
    const current = this;
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function () {
      current.plugins.index();
    }, 200);

    this.refresh();
  }

  refresh(): void {
    this.publicService.campaigns({ meta: { per_page: 999999 } }).subscribe((data) => {
      this.campaigns = data.campaigns || [];
      const subHeader = _.map(this.campaigns, (campaign) => ({
        href: `/campaigns/${campaign.id}/${campaign.url_slug || ''}`,
        title: `${campaign.name}`,
      }));

      if (subHeader.length) {
        // const navItems = [...this.navItems];
        this.navItems[2] = {
          href: 'campaigns',
          title: 'All Campaigns',
          children: true,
          child: subHeader,
        };

        // this.navItems = [...navItems];
      }
    });
  }
}
