import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';
import { PublicService } from '../../../../app/core/services/public.service';
@Component({
  selector: 'app-landing-banner',
  templateUrl: './landing-banner.component.html',
  styleUrls: ['./landing-banner.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingBannerComponent implements OnInit{
  
  owlOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    dots: false,
    navSpeed: 700,
    autoplayHoverPause: true,
    navText: ['', ''],
    lazyLoadEager: 1,
    center: true,
    autoWidth: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };

  slides = [];

  constructor(private publicService: PublicService) {}

  activeSlides: SlidesOutputData;

  getSlides(data: SlidesOutputData) {
    //this.activeSlides = data;
  }

  retrieveSlides() {
    this.publicService.getHomeBanner().subscribe((data) => {
      setTimeout(() => {
        data.forEach((value, index) => {
          // link = value.campaign_url

          let img = {
            id: index,
            src: value.banner_url,
            alt: '',
            title: '',
            url: value.campaign_url,
          };
          this.slides.push(img);
        });
      },300);
    });
  }

  ngOnInit(): void {
    this.retrieveSlides();
  }
   
}
