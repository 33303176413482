import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SofboxModule } from '@app/sofbox/sofbox.module';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './landing.component';
import { LandingBannerComponent } from './components/landing-banner/landing-banner.component';
import { LandingAboutComponent } from './components/landing-about/landing-about.component';
import { LandingAllCampaignsComponent } from './components/landing-all-campaigns/landing-all-campaigns.component';
import { LandingActiveCampaignsComponent } from './components/landing-active-campaigns/landing-active-campaigns.component';
import { LandingPastCampaignsComponent } from './components/landing-past-campaigns/landing-past-campaigns.component';
import { SplitPipe } from '@app/pipes/split.pipe';

@NgModule({
  declarations: [
    LandingComponent,
    LandingBannerComponent,
    LandingAboutComponent,
    LandingAllCampaignsComponent,
    LandingActiveCampaignsComponent,
    LandingPastCampaignsComponent,
    SplitPipe,
  ],
  imports: [CommonModule, SofboxModule, CarouselModule, LandingRoutingModule],
})
export class LandingModule {}
