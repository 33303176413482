import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseSurveyComponent } from '../base-survey/base-survey.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-survey-checkbox-text',
  templateUrl: './survey-checkbox-text.component.html',
  styleUrls: ['./survey-checkbox-text.component.css'],
  providers: [
    {
      provide: BaseSurveyComponent,
      useExisting: SurveyCheckboxTextComponent,
    },
  ],
})
export class SurveyCheckboxTextComponent extends BaseSurveyComponent {
  form: FormGroup;
  formArray: any;

  result: any[] = [];
  clicked = false;

  checkboxtext: FormArray;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.form = this.formBuilder.group({
      checkboxtext: this.formBuilder.array([], [Validators.required]),
    });
  }

  ngOnInit() {
    this.sortedOption = _.orderBy(this.choice, 'ordering');
  }

  onCheckboxChange(e) {
    this.checkboxtext = this.form.get('checkboxtext') as FormArray;
    if (e.target.checked) {
      this.checkboxtext.push(new FormControl(e.target.value));
    } else {
      const index = this.checkboxtext.controls.findIndex((x) => x.value === e.target.value);
      this.checkboxtext.removeAt(index);
    }
    this.result = _(this.choice).keyBy('id').at(this.checkboxtext.value).value();
  }

  getChoice() {
    this.checkboxtext.clear();
    return this.result;
  }
}
