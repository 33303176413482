import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-landing-about',
  templateUrl: './landing-about.component.html',
  styleUrls: ['./landing-about.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingAboutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
