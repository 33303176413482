import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SurveyQuestion } from '@app/core/models/survey-question.model';
import { BaseSurveyComponent } from '../base-survey/base-survey.component';
import { SurveyQuestionService } from '@app/core/services/survey-question.service';
import { IQuery } from '@app/core/models/query.model';
import * as _ from 'lodash';
import { PublicService } from '@app/core/services/public.service';
import { concat, forkJoin } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Campaign } from '@app/core/models/campaign.model';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.css'],
})
export class SurveyListComponent implements OnInit, AfterViewInit {
  @ViewChildren(BaseSurveyComponent)
  baseRef: QueryList<BaseSurveyComponent>;

  @Input()
  campaign: Campaign = {};

  surveyQuestions: SurveyQuestion[] = [];
  choicesList: any[];
  choice: any[];
  answer: any[];

  isButtonVisible = false;
  clicked = true;
  toggleBool = true;
  index = 0;
  question = null;
  limit = false;
  totalQuestion: number;
  is_selected = false;

  constructor(private surveyQuestionService: SurveyQuestionService, private PublicService: PublicService) {}

  ngOnInit() {
    this.question = this.surveyQuestions[this.index];

    const query: IQuery = {
      filter: new Map<string, any>([['campaign', this.campaign.id]]),
      params: new Map<string, any>([
        ['include[]', 'survey_question.*'],
        ['per_page', 9999],
      ]),
    };

    this.surveyQuestionService.getSurvey(query).subscribe(
      (data) => {
        setTimeout(() => {
          if (data.hasOwnProperty(this.surveyQuestionService.plural)) {
            this.surveyQuestions = [...data[this.surveyQuestionService.plural]];
            this.surveyQuestions = _.orderBy(this.surveyQuestions, 'ordering');
            this.choicesList = [...data['survey-choices']];
            this.totalQuestion = this.surveyQuestions.length;

            this.getQuestion(this.index);
          }
        }, 300);
      },
      (error) => {
        setTimeout(() => {
          this.surveyQuestions = [];
        }, 300);
      }
    );
  }

  ngAfterViewInit() {}

  submit() {
    this.baseRef.toArray().forEach((element) => {
      const result = element.getChoice();
      const observableList = [];

      if (result.length === 0) {
        Swal.fire({
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#FFD966',
          title: 'Please Select a Choice!',
        });
      } else {
        result.forEach((data) => {
          observableList.push(this.PublicService.submitAnswer(data.survey_question, data.id));
        });
        const resultObs = this.PublicService.getSurveyResult(element.question.id);

        concat(forkJoin(observableList).pipe(ignoreElements()), resultObs).subscribe((data) => {
          setTimeout(() => {
            this.answer = data['survey-result'];
            element.changeToResultMode();
            this.isButtonVisible = true;
            this.clicked = false;

            this.answer.forEach((returnChoice) => {
              returnChoice.is_selected =
                _.findIndex(result, {
                  id: returnChoice.id,
                }) >= 0;
            });
          }, 300);
        });
      }
    });

    // if there is no more question after pressing submit button
    this.limit = this.surveyQuestions.length - 1 === this.index;
  }

  next() {
    this.clicked = true;
    this.toggleBool = true;
    this.isButtonVisible = false;
    this.baseRef.toArray().forEach((element) => {
      element.changeToSurveyMode();
    });
    if (this.surveyQuestions.length >= this.index) {
      this.index++;
      this.getQuestion(this.index);
    }
    this.limit = this.surveyQuestions.length - 1 === this.index;
  }

  changeEvent(event) {
    if (event.target.checked) {
      this.toggleBool = false;
    } else {
      this.toggleBool = false;
    }
  }

  getQuestion(index: number) {
    this.question = this.surveyQuestions[index];
    this.choice = _.filter(this.choicesList, {
      survey_question: this.question.id,
    });
  }
}
