import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Campaign, CAMPAIGN_CATEGORY } from '@app/core/models/campaign.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PublicService } from '@app/core/services/public.service';
import { PluginsService } from '@app/sofbox/plugins.service';
import { Post } from '@app/core/models/post.model';
import moment from 'moment';

@Component({
  selector: 'app-campaign-about',
  templateUrl: './campaign-about.component.html',
  styleUrls: ['./campaign-about.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignAboutComponent implements OnInit, AfterViewInit {
  @Input()
  campaign: Campaign = {};
  @Output()
  postsAdded = new EventEmitter<void>();

  closeResult: string;
  loading = false;
  safeHtml: SafeHtml;
  safeHtml2: SafeHtml;
  safeIframe: SafeHtml;
  show_update: boolean;
  update_description: string;
  categoryName: string;

  @ViewChild('iframe', { static: false })
  iframe: ElementRef;

  dirty = false;

  constructor(private renderer: Renderer2, private modalService: NgbModal, private sanitizer: DomSanitizer, private plugins: PluginsService) {}

  ngOnInit() {
    this.safeHtml = this.bypassSecurityTrustHtml(this.campaign.long_description);
    this.safeHtml2 = this.bypassSecurityTrustHtml(this.campaign.update_description);
    this.safeIframe = this.bypassSecurityTrustHtml(this.campaign.external_embed_code);
    this.campaign.start_at = moment(this.campaign.start_at).format('D MMM YYYY');
    this.campaign.end_at = moment(this.campaign.end_at).format('D MMM YYYY');
    if (this.campaign.category == CAMPAIGN_CATEGORY.BETTER_LIVING) {
      this.categoryName = 'BETTER LIVING';
    } else {
      this.categoryName = 'BETTER COMMUNITY';
    }
    // Init all plugins...
    const current = this;
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function () {
      current.plugins.index();
    }, 200);
    if (this.campaign.status === 'past' && this.campaign.update_description !== '') {
      this.show_update = true;
    }
  }

  open(content) {
    this.loading = true;

    this.modalService.open(content, { size: 'lg', windowClass: 'custom-modal-lg' }).result.then(
      (result) => {},
      (reason) => {
        if (this.dirty) {
          this.postsAdded.next();
          this.dirty = false;
        }
      }
    );
  }

  ngAfterViewInit() {}

  onIframeLoad() {
    this.loading = false;
  }

  bypassSecurityTrustHtml(text: string) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  added(post: Post) {
    this.dirty = true;
  }
}
