import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { POST_THEME, POST_THEME_OPTIONS, Post } from '@app/core/models/post.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Observable, of } from 'rxjs';
import { Campaign, HERO_CAMPAIGN_NOMINATION_CHOICE, HERO_CAMPAIGN_INDUSTRY_CHOICE } from '@app/core/models/campaign.model';
import { PublicService } from '@app/core/services/public.service';
import * as _ from 'lodash';
import moment from 'moment';
import { mergeMap } from 'rxjs/operators';
import { environment } from '@env/environment';

@Component({
  selector: 'app-campaign-post-form',
  templateUrl: './campaign-post-form.component.html',
  styleUrls: ['./campaign-post-form.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignPostFormComponent implements OnInit, OnDestroy {
  readonly POST_THEME = POST_THEME;
  readonly POST_THEME_OPTIONS = POST_THEME_OPTIONS;
  readonly HERO_CAMPAIGN_NOMINATION_CHOICE = HERO_CAMPAIGN_NOMINATION_CHOICE;
  readonly HERO_CAMPAIGN_INDUSTRY_CHOICE = HERO_CAMPAIGN_INDUSTRY_CHOICE;

  readonly HERO_OTHER = 'other';

  @Input()
  campaign: Campaign = {};
  @Output()
  added = new EventEmitter<Post>();

  form: FormGroup;
  error: string;
  success = false;
  submitting = false;

  siteKey: any;

  private unsubscribeAll: Subject<any>;

  constructor(private formBuilder: FormBuilder, private publicService: PublicService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.siteKey = environment.siteKey;
    const urlRegex = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;

    this.form = this.formBuilder.group({
      poster_name: [
        {
          value: '',
          disabled: !this.campaign.post_name_enable,
        },
        Validators.required,
      ],
      poster_phone: [
        {
          value: '',
          disabled: !this.campaign.post_phone_enable,
        },
        Validators.required,
      ],
      poster_email: [
        {
          value: '',
          disabled: !this.campaign.post_email_enable,
        },
        [Validators.email, Validators.required],
      ],
      description: [
        {
          value: '',
          disabled: !this.campaign.post_description_enable,
        },
        this.descriptionValidator(this.campaign),
      ],
      // headline: [''],
      poster_instagram: [
        {
          value: '',
          disabled: !this.campaign.post_instagram_enable,
        },
      ],
      url: [
        {
          value: '',
          disabled: !this.campaign.post_url_enable,
        },
        Validators.pattern(urlRegex),
      ],
      // theme: [POST_THEME.THEME_1],
      upload_image: [
        {
          value: null,
          disabled: !this.campaign.post_image_enable,
        },
      ],
      hero_nomination: [
        {
          value: 'self',
          disabled: !this.campaign.is_hero_campaign,
        },
        Validators.required,
      ],
      hero_name: [
        {
          value: '',
          disabled: !this.campaign.is_hero_campaign,
        },
      ],
      hero_phone: [
        {
          value: '',
          disabled: !this.campaign.is_hero_campaign,
        },
      ],
      hero_email: [
        {
          value: '',
          disabled: !this.campaign.is_hero_campaign,
        },
        Validators.email,
      ],
      hero_industry: [
        {
          value: '',
          disabled: !this.campaign.is_hero_campaign,
        },
        Validators.required,
      ],
      recaptcha: ['', Validators.required],
    });

    if (this.campaign.is_hero_campaign) {
      this.form.get('hero_nomination').valueChanges.subscribe((value) => {
        if (value === this.HERO_OTHER) {
          this.form.get('hero_name').setValidators(Validators.required);
          this.form.get('hero_phone').setValidators(Validators.required);
        } else {
          this.form.get('hero_name').clearValidators();
          this.form.get('hero_phone').clearValidators();
        }

        this.form.get('hero_name').updateValueAndValidity();
        this.form.get('hero_phone').updateValueAndValidity();
        this.form.get('hero_email').updateValueAndValidity();
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  resolved(token) {}

  save() {
    if (!this.submitting) {
      this.submitting = true;
      this.error = null;
      this.publicService
        .addBersamaPost(
          Object.assign(
            {
              campaign: this.campaign.id,
              publish_at: moment().utc(),
              is_active: true,
            },
            _.omit(this.form.value, ['upload_image'])
          )
        )
        .pipe(
          mergeMap((data) => {
            if (this.campaign.post_image_enable && this.form.get('upload_image').value) {
              return this.uploadImage(data.id, this.form.get('upload_image').value);
            } else {
              return of(data);
            }
          })
        )
        .subscribe(
          (data) => {
            setTimeout(() => {
              this.success = true;
              this.submitting = false;
              this.added.next(data);
            }, 300);
          },
          (error) => {
            setTimeout(() => {
              this.submitting = false;
              this.error = error;
            }, 300);
          }
        );
    }
  }

  onFileChanged(event): void {
    const files: FileList = event.target.files;
    this.form.get('upload_image').setValue(files[0]);
    this.form.markAsDirty();
  }

  reset() {
    setTimeout(() => {
      this.form.reset();
      this.success = false;
    });
  }

  descriptionValidator(campaign: Campaign) {
    const validatorArray: Validators[] = [Validators.required];
    if (campaign.post_description_min_length != null) {
      validatorArray.push(Validators.minLength(campaign.post_description_min_length));
    }
    if (campaign.post_description_max_length != null) {
      validatorArray.push(Validators.maxLength(campaign.post_description_max_length));
    }
    return validatorArray;
  }
  private uploadImage(id: number, file: File): Observable<Post> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.publicService.uploadBersamaPostImage(id, formData);
  }
}
